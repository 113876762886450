<!-- eslint-disable vue/multi-word-component-names -->
<script>
import axios from 'axios';

import ProducerCard from '@/components/Producer/ProducerCard.vue';

export default {
    data() {
        return {
            search: null,
            loading: false,
            allProducers: [],
            imageDimensions: {
                width: 0,
                height: 0
            }
        }
    },

    components: {
        ProducerCard
    },

    mounted() {
        this.fetchProducers();
    },

    computed: {
        producers() {
            if (!this.search) {
                return this.allProducers
            }

            return this.allProducers.filter(producer => producer.name.toLowerCase().includes(this.search.toLowerCase()))
        },

        imageClass() {
            const { width, height } = this.imageDimensions;
            if (width > height) {
                return 'landscape';
            } else {
                return 'portrait';
            }
        }
    },

    methods: {
        fetchProducers() {
            this.loading = true
            axios.get(`${import.meta.env.VITE_API_URL}/wp-json/regiomarkt/v1/producers`)
                .then(response => {
                    this.allProducers = response.data.producers
                    this.loading = false
                })
                .catch(error => {
                    this.$q.notify({
                        color: 'negative',
                        icon: 'report_problem',
                        message: 'Ein Fehler ist aufgetreten. Bitte versuchen Sie es später erneut.',
                        position: 'top',
                        timeout: 3000
                    });
                    console.error('Error fetching producers:', error);
                    this.loading = false
                })
        },

        getImageDimensions() {
            /*const img = this.$refs.productImage.$el.querySelector('img');
            if (img) {
                console.log(img)
                this.imageDimensions.width = img.naturalWidth;
                this.imageDimensions.height = img.naturalHeight;
            }*/
        }
    }
}
</script>
<template>
    <div class="q-py-lg">
        <div class="q-px-md q-pb-lg container-sm">
            <h1 class="text-h1 text-center">Unsere Produzenten</h1>
            <div class="text-center">
                Alle Partnerbetriebe werden von uns persönlich besucht und geprüft. Sie stehen für Qualität, Frische und 100%ige Regionalität. Dabei achten Sie auf einen nachhaltigen Anbau und sorgsamen Umgang mit den Tieren.
            </div>
        </div>
        <div class="banner q-mt-md"></div>
        <div class="q-px-md q-py-lg container-sm">
            <QInput v-model="search" placeholder="Wen suchst du?" class="full-width q-my-lg" clearable>
                <template #prepend>
                    <QIcon name="search" />
                </template>
            </QInput>
        </div>

        <div class="container">
            <Transition 
                enter-active-class="animate__animated animate__fadeInLeft animate__faster animate__delay-1s" 
                leave-active-class="animate__animated animate__faster animate__fadeOutRight"
            >
                <div v-if="loading" class="q-px-md q-py-lg row q-col-gutter-lg justify-center">
                    <div v-for="i in 6" :key="i" class="col-12 col-lg-3 col-md-4 col-sm-6">
                        <QCard bordered>
                            <QSkeleton type="rect" height="200px" widht="100%" />
                            <QCardSection>
                                <QSkeleton class="q-mx-auto" type="text" width="150px" height="30px" />
                                <QSeparator class="dashed q-my-md q-mx-auto" width="150px" />
                                <QSkeleton class="q-mx-auto" type="text" height="20px" width="120px" />
                                <QSkeleton class="q-mx-auto" type="text" height="20px" width="210px" />
                                <QSkeleton class="q-mx-auto" type="text" height="20px" width="240px" />
                                <QSkeleton class="q-mx-auto" type="text" height="20px" width="180px" />
                            </QCardSection>
                        </QCard>
                    </div>
                </div>
                <div v-else class="q-px-md q-py-lg row q-col-gutter-lg justify-center row justify-center items-stretch">
                    <div v-for="producer in producers" :key="producer.id" class="col-12 col-lg-3 col-md-4 col-sm-6">
                        <ProducerCard :producer="producer" class="full-height" />
                    </div>
                </div>
            </Transition>
        </div>
    </div>
</template>

<style scoped lang="scss">
    @use '@/sass/mixins';

    .banner {
        height: 300px;
        width: 100%;
        background-image: url('@/assets/banner.png');
        background-size: cover;

        @include mixins.wave-mask;
    }
</style>